<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">实验终点</h3>
    <div class="detail">
      <h4 class="title">大体解剖观察记录</h4>
      <el-form ref="end" size="small" :model="end" class="addForm" label-suffix=":"
               label-width="90px">
        <el-tabs v-model="nowNumber" class="tabsCage">
          <el-tab-pane
              :key="key"
              v-for="(item, key) in tabs"
              :name="key"
          >
            <span slot="label">{{ key }}</span>
            <p class="remarkTit">备注：①急性失血，②空气栓塞，③脊椎脱臼，④其他 拷贝</p>
            <el-table :data="item" border
                      class="tableMember"
                      :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                      style="width: calc(100% - 12px);font-size:14px;color:#333">
              <el-table-column label="动物编号" prop="aniNo" show-overflow-tooltip></el-table-column>
              <el-table-column label="耳号" prop="overbit" show-overflow-tooltip></el-table-column>
              <el-table-column label="性别">
                <template slot-scope="scope">{{ scope.row.gender == 1 ? '♂' : '♀' }}</template>
              </el-table-column>
              <el-table-column label="处理方式" width="260" class-name="isClass">
                <template slot-scope="scope">
                  <!--  0:  直接丢弃   1：解剖处理-->
                  <template v-if="scope.row.show">
                    <el-radio-group v-model="scope.row.handle" class="radioGroup">
                      <el-radio :label="0">回收箱丢弃</el-radio>
                      <el-radio :label="1">解剖处理</el-radio>
                    </el-radio-group>
                  </template>
                  <span v-else>{{ scope.row.handle == 0 ? '回收箱丢弃' : '解剖处理' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="死亡类型" class-name="isClass" width="200">
                <template slot-scope="scope">
                  <template v-if="scope.row.handle==1">
                    <el-select v-model="scope.row.deathType" v-if="scope.row.show" size="small"
                               style="width: 100%"
                               placeholder="请选择死亡类型">
                      <el-option
                          v-for="(item,index) in options"
                          :key="index"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                    <template v-else>
                      <template v-if="scope.row.deathType==0">①</template>
                      <template v-if="scope.row.deathType==2">②</template>
                      <template v-if="scope.row.deathType==3">③</template>
                      <template v-if="scope.row.deathType==4">④</template>
                    </template>
                  </template>

                </template>
              </el-table-column>
              <el-table-column label="大体解剖观察" min-width="150" class-name="isClass" show-overflow-tooltip>
                <template slot-scope="scope">
                  <template v-if="scope.row.handle == 1">
                    <template v-if="scope.row.show">
                      <el-input size="small" placeholder="大体解剖观察" v-model="scope.row.dissection"></el-input>
                    </template>
                    <span v-else>{{ scope.row.dissection }}</span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="备注" min-width="150" class-name="isClass" show-overflow-tooltip>
                <template slot-scope="scope">
                  <template v-if="scope.row.show">
                    <el-input size="small" placeholder="请输入备注" v-model="scope.row.remark"></el-input>
                  </template>
                  <span v-else>{{ scope.row.remark }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" v-if="state==0||state==3">
                <template slot-scope="scope">
                  <div class="editColor">
                    <el-button type="text" @click="saveAs(item,scope.row,scope.$index)">{{
                        scope.row.show ? '保存' : '编辑'
                      }}
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <h3 class="tit">指定复核人</h3>
        <el-form-item label="复核人员">
          <el-radio-group v-model="end.reviewName" class="radioGroup" :disabled="disabled">
            <el-radio v-for="item in userList" :label="item.name" :key="item.userId"
                      @click.native="getAppId(item.userId,item.name)"
            > {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="fromSave">
          <template v-if="state==null||state==0|| state==3">
            <el-button type="primary" size="small" :disabled="disabled" @click="submitForm('end',1)">记录完成</el-button>
            <el-button type="primary" plain size="small" :disabled="disabled" @click="submitForm('end',0)">保存
            </el-button>
          </template>
          <template v-if="state!=null">
            <el-button type="primary" plain size="small" @click="$emit('changePageGroup', false,3)">导出预览</el-button>
          </template>
        </div>
      </el-form>
    </div>
  </div>

</template>

<script>

import moment from "moment";

export default {
  name: "record",
  props: ['id'],
  data() {
    return {
      endMode: false,
      end: {
        reviewer: null,
        reviewName: '',
        endDetailList: []
      },
      tabs: {},
      nowNumber: 0,
      userList: [],
      options: [{
        value: 0,
        label: '① 急性失血'
      }, {
        value: 2,
        label: '② 空气栓塞'
      }, {
        value: 3,
        label: '③ 颈椎脱臼'
      }, {
        value: 4,
        label: '④ 其他'
      }],
      state: null,
      disabled: false
    }
  },
  mounted() {
    this.$get('/subject/member/list/' + this.id).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
    this.getData()
  },
  methods: {
    // 指定复核人员
    getAppId(id, name) {
      this.end.reviewer = id
      this.end.reviewName = name
    },
    getData() {
      this.$get('/subject/end/' + this.id).then(res => {
        if (res.status == 200) {
          if (res.data.end != null) {
            this.state = res.data.end.state
            if (this.state == 1 || this.state == 2) {
              this.disabled = true
            }
            this.end.reviewer = res.data.end.reviewer
            this.end.reviewName = res.data.end.reviewName
          }
          let arr = []
          let vm = this;
          for (const key in res.data.groupMap) {
            arr.push(key)
            res.data.groupMap[key].forEach(obj => {
              if (this.state == null) {
                obj.show = true
                vm.$set(obj, 'deathType', null)
                vm.$set(obj, 'remark', '')
                vm.$set(obj, 'dissection', '')
                vm.$set(obj, 'handle', 0)
              }
            })
            this.tabs = res.data.groupMap
            this.nowNumber = arr[0]
          }
        }
      })
    },
    // 保存状态写下修改数据
    saveAs(item, row, index) {
      row.show = !row.show
      this.$delete(row,'createDate')
      // row.createDate = moment(row.createDate).format("YYYY-MM-DD HH:mm:ss")
      if (!row.show) {
        this.$put('/subject/end/detail', row).then(res => {
          if (res.status == 200) {
            this.$message.success("保存成功")
          }
        })
      }
      this.$set(item, index, row)
    },
    // 提交数据
    submitForm(formName, state) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.end.state = state
          this.end.subId = Number(this.id)
          let url;
          if ($.trim(this.end.reviewer).length === 0) {
            this.$message.warning("请选择复核人")
            return false;
          }
          if (this.state == null) {
            let lists = [], message2 = [], message3 = [];
            let result = true
            for (const key in this.tabs) {
              this.tabs[key].forEach(item => {
                if (item.handle == 1) {
                  if (item.deathType == null) {
                    result = result && false
                    message2.push('死亡类型不能为空')
                  }
                  if (item.dissection == '') {
                    result = result && false
                    message3.push('解剖观察结果不能为空')
                  }
                }
                lists.push(item)
              })
            }
            this.end.endDetailList = lists
            if (!result) {
              if (message2 && message2.length > 0) {
                return this.$message({message: message2[0], type: 'warning'})
              }
              if (message3 && message3.length > 0) {
                return this.$message({message: message3[0], type: 'warning'})
              }
            }
            url = this.$postJson('/subject/end', this.end)
          } else {
            this.$delete(this.end, 'endDetailList')
            url = this.$putJson('/subject/end', this.end)
          }
          url.then(res => {
            if (res.status == 201 || res.status == 200) {
              this.$message.success('提交成功')
              this.$router.go(-1)
            }
          })
        } else {
          return false;
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>

